import { BigNumberish, ethers, ContractInterface } from 'ethers'
import { Contract } from '@ethersproject/contracts'
import { Provider, Web3Provider } from '@ethersproject/providers'

import localForage from 'localforage'

import { formatUnits } from 'ethers/lib/utils'
import { SYNDICATE_TOKEN_LIST_BASE_URI } from './constants'
import { ChainData } from './types'
import { supportedChains } from './chains'
import { Currency } from '../entities'

export function getChainData(chainId?: number): ChainData | undefined {
  const chainData = supportedChains.filter(
    (chain: ChainData) => chain.chainId === chainId,
  )[0]

  return chainData
}

export function getShortAddress(address: string): string {
  return `${address.substring(0, 6)}...${address.substring(address.length - 4)}`
}

export function prettyFormat(value: BigNumberish, unitName?: number): number {
  return +((+formatUnits(value, unitName)).toFixed(4))
}

export function getTokenLogoURI(symbol: string): string {
  return `${SYNDICATE_TOKEN_LIST_BASE_URI}/assets/asset_${symbol}.svg`
}

/**
 * Validates an address and returns the parsed (checksummed) version of that address
 * @param address the unchecksummed hex address
 */
export function validateAndParseAddress(address: string): string {
  try {
    return ethers.utils.getAddress(address)
  } catch (error) {
    throw new Error(`${address} is not a valid address.`)
  }
}

export const getContract = (
  address: string,
  abi: ContractInterface,
  provider: Provider | Web3Provider,
): Contract => new Contract(address, abi, provider as Provider)

export function sleep(ms: number): Promise<unknown> {
  // eslint-disable-next-line no-promise-executor-return
  return new Promise((resolve) => setTimeout(resolve, ms))
}

export function timeout(prom: Promise<unknown>, time: number): Promise<unknown> {
  // eslint-disable-next-line no-promise-executor-return
  return Promise.race([prom, new Promise((_r, rej) => setTimeout(rej, time))])
}

export function getPriceForCurrency(currency: Currency, value: BigNumberish, price: number): number {
  const convertedValue = +formatUnits(value, currency.decimals)
  return +((convertedValue * price).toFixed(4))
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function createStorage(dbName: string): any {
  const db = localForage.createInstance({
    name: dbName,
  })
  return {
    db,
    getItem: db.getItem,
    setItem: db.setItem,
    removeItem: db.removeItem,
  }
}
