import { ReactNode, useEffect } from 'react'
import CircularProgress from '@mui/material/CircularProgress'
import Container from '@mui/material/Container'
import Box from '@mui/material/Box'

import { useWeb3React, UnsupportedChainIdError } from '@web3-react/core'
import { Web3Provider } from '@ethersproject/providers'

import { useAppSelector, useAppDispatch } from '../app/hooks'
import { setLoaded, authorize, deauthorize } from '../features/wallet/walletSlice'
import { injected } from '../helpers/connectors'
import { closeModal, ModalType, openModal } from '../features/modal/modalSlice'

const MetaMaskProvider = ({ children }: { children: ReactNode }): JSX.Element => {
  const {
    active: networkActive, account, chainId, error: networkError, activate: activateNetwork,
  } = useWeb3React<Web3Provider>()
  const isUnsupportedChainIdError = networkError instanceof UnsupportedChainIdError

  const { loaded: isLoaded, forcedExit } = useAppSelector((state) => state.wallet)
  const dispatch = useAppDispatch()

  const modalOpened = useAppSelector(
    (state) => Object.keys(state.modal).filter((key) => state.modal[key as unknown as ModalType] === true).length > 0,
  )
  const isUnsupportedModalOpened = useAppSelector(
    (state) => state.modal[ModalType.WRONG_NETWORK_MODAL],
  )

  useEffect(() => {
    if (isUnsupportedChainIdError && modalOpened) {
      dispatch(closeModal())
      dispatch(openModal(ModalType.WRONG_NETWORK_MODAL))
    } else if (!isUnsupportedChainIdError && isUnsupportedModalOpened) {
      dispatch(closeModal())
    }
  }, [modalOpened, dispatch, isUnsupportedChainIdError, isUnsupportedModalOpened])

  useEffect(() => {
    (async () => {
      if (forcedExit) return

      let isAuthorized = false
      try {
        isAuthorized = await injected.isAuthorized()
      } catch (e) {
        console.log('error in authorization', e)
        dispatch(setLoaded(true))
        return
      }

      console.log(
        'Got MetaMask authorized',
        isAuthorized,
        'account',
        account,
        'chainId',
        chainId,
        'networkActive',
        networkActive,
        'networkError',
        networkError,
      )

      if (!isAuthorized) {
        dispatch(deauthorize())
        return
      }

      if (isAuthorized && !networkActive && !networkError) {
        try {
          await activateNetwork(injected)
        } catch (e) {
          console.log('error in activation', e)
          dispatch(deauthorize())
        }
      }

      if (isAuthorized && account && chainId) {
        dispatch(authorize({ account, chainId }))
      } else {
        dispatch(setLoaded(true))
      }
    })()
  }, [dispatch, account, chainId, activateNetwork, networkActive, networkError, forcedExit])
  if (isLoaded) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{children}</>
  }
  return (
    <Container maxWidth="md">
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          minHeight: 'calc(100vh - 100px)',
        }}
      >
        <CircularProgress />
      </Box>
    </Container>
  )
}

export default MetaMaskProvider
