import { Button } from '@mui/material'

import { useAppDispatch } from '../../app/hooks'
import { ModalType, openModal } from '../../features/modal/modalSlice'

interface ButtonProps {
  size?: 'small' | 'medium' | 'large'
  variant?: 'text' | 'outlined' | 'contained'
  color?: 'primary' | 'secondary' |
  'inherit' | 'success' | 'error' | 'info' | 'warning'
}

const ConnectButton = (
  props: ButtonProps,
): JSX.Element => {
  const dispatch = useAppDispatch()
  const triggerModal = () => dispatch(openModal(ModalType.CONNECT))

  return (
    <Button
      fullWidth
      onClick={triggerModal}
      {...props}
    >
      Connect wallet
    </Button>
  )
}

export default ConnectButton
