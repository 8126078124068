import {
  configureStore, ThunkAction, AnyAction, Middleware, combineReducers,
} from '@reduxjs/toolkit'

import bridgeSlice from '../features/bridge/bridgeSlice'
import modalSlice from '../features/modal/modalSlice'
import walletSlice from '../features/wallet/walletSlice'
import web3ApiSlice from '../features/api/web3ApiSlice'
import chainListApiSlice from '../features/api/chainListApiSlice'
import tokenListApiSlice from '../features/api/tokenListApiSlice'
import transactionSlice from '../features/wallet/transactionSlice'
import cosignerApiSlice from '../features/api/cosignerApiSlice'
import settingsSlice from '../features/settings/settingsSlice'
import priceApiSlice from '../features/api/priceApiSlice'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const middlewares: Array<Middleware> = [
  tokenListApiSlice.middleware,
  web3ApiSlice.middleware,
  chainListApiSlice.middleware,
  cosignerApiSlice.middleware,
  priceApiSlice.middleware,
]
/* eslint-disable global-require */
if (process.env.NODE_ENV === 'development') {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const { logger }: { logger: Middleware } = require('redux-logger')
  middlewares.push(logger)
}

/* eslint-enable global-require */
const rootReducer = combineReducers({
  bridge: bridgeSlice,
  wallet: walletSlice,
  modal: modalSlice,
  transaction: transactionSlice,
  settings: settingsSlice,
  [tokenListApiSlice.reducerPath]: tokenListApiSlice.reducer,
  [web3ApiSlice.reducerPath]: web3ApiSlice.reducer,
  [chainListApiSlice.reducerPath]: chainListApiSlice.reducer,
  [cosignerApiSlice.reducerPath]: cosignerApiSlice.reducer,
  [priceApiSlice.reducerPath]: priceApiSlice.reducer,
})

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: false,
    // ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
  }).concat(middlewares),
  devTools: process.env.NODE_ENV !== 'production',
})

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  AnyAction
>;
