import { useCallback, useMemo } from 'react'
import LoadingButton from '@mui/lab/LoadingButton'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import Typography from '@mui/material/Typography'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import Button from '@mui/material/Button'

import { useWeb3React } from '@web3-react/core'
import { Web3Provider } from '@ethersproject/providers'

import { Stack } from '@mui/material'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import { closeModal, ModalType } from '../../features/modal/modalSlice'

import BootstrapDialogTitle from './BootstrapDialogTitle'
import { supportedChains } from '../../helpers/chains'
import ImgIcon from '../icons/ImgIcon'
import useFetchMetamaskAPI from '../../hooks/useFetchMetamaskAPI'
import { ChainData } from '../../helpers/types'
import { getBridgeSettings } from '../../features/settings/settingsSlice'

const WrongNetworkModal = (): JSX.Element => {
  const dispatch = useAppDispatch()
  const open = useAppSelector((state) => state.modal[ModalType.WRONG_NETWORK_MODAL])
  const bridgeSettings = useAppSelector(getBridgeSettings)

  const { library: provider } = useWeb3React<Web3Provider>()
  const { switchNetwork, isLoading } = useFetchMetamaskAPI(provider)

  const handleClose = useCallback(() => dispatch(closeModal()), [dispatch])

  const handleNetwork = (chain: ChainData) => () => {
    switchNetwork(chain.chainId)
  }

  const chains = useMemo(
    () => (!isLoading ? supportedChains.filter((chain) => (bridgeSettings.useExperimental || !chain.isTest)) : []),
    [bridgeSettings.useExperimental, isLoading],
  )

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="wrong-network-dialog-title"
      open={open}
    >
      <BootstrapDialogTitle id="wrong-network-dialog-title" onClose={handleClose}>
        Wrong network
      </BootstrapDialogTitle>
      <DialogContent dividers>
        <Stack sx={{ width: 400 }}>
          <Typography sx={{ paddingLeft: 1 }}>
            Please connect to the appropriate EVM network.
          </Typography>
          <List dense sx={{ paddingBottom: 2 }}>
            {isLoading && (
              <ListItem sx={{ padding: 0 }}>
                <LoadingButton
                  fullWidth
                  loading={isLoading}
                  variant="text"
                  color="secondary"
                  sx={{
                    justifyContent: 'space-between',
                    textTransform: 'none',
                  }}
                  size="medium"
                >
                  Network
                </LoadingButton>
              </ListItem>
            )}
            {chains.map((chain) => (
              <ListItem key={chain.chainId} sx={{ padding: 0 }}>
                <Button
                  fullWidth
                  variant="text"
                  color="secondary"
                  sx={{
                    justifyContent: 'space-between',
                    textTransform: 'none',
                  }}
                  size="medium"
                  onClick={handleNetwork(chain)}
                  endIcon={(
                    <ImgIcon src={chain.nativeCurrency.logoURI} />
                  )}
                >
                  {chain.name}
                </Button>
              </ListItem>
            ))}
          </List>
        </Stack>
      </DialogContent>
    </Dialog>
  )
}

export default WrongNetworkModal
