import React, { useState } from 'react'
import { styled } from '@mui/material/styles'
import IconButton from '@mui/material/IconButton'
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import SettingsIcon from '@mui/icons-material/Settings'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import Box from '@mui/material/Box'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import { getBridgeSettings, updateBridgeSettings } from '../../features/settings/settingsSlice'

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: (theme.palette.mode === 'light') ? '#f5f5f9' : '#1b1b1b',
    color: (theme.palette.mode === 'light') ? 'rgba(0, 0, 0, 0.87)' : 'white',
    maxWidth: 500,
    fontSize: theme.typography.pxToRem(12),
    border: `1px solid ${(theme.palette.mode === 'light') ? '#2b2b2b' : '#69f6d1'}`,
    borderRadius: 16,
  },
}))

const SettingsCheckbox = styled(Checkbox)(({ theme }) => ({
  color: (theme.palette.mode === 'light') ? '#2b2b2b' : '#69f6d1',
  '&.Mui-checked': {
    color: (theme.palette.mode === 'light') ? '#2b2b2b' : '#69f6d1',
  },
}))

const BridgeSettingsButton = (): JSX.Element => {
  const dispatch = useAppDispatch()
  const bridgeSettings = useAppSelector(getBridgeSettings)
  const [open, setOpen] = useState(false)

  const handleTooltipClose = () => {
    setOpen(false)
  }

  const handleTooltipOpen = () => {
    setOpen((prev) => !prev)
  }

  const handleUseExperimental = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(updateBridgeSettings({
      ...bridgeSettings,
      useExperimental: event.target.checked,
    }))
  }

  const handleSafeMax = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(updateBridgeSettings({
      ...bridgeSettings,
      safeMax: event.target.checked,
    }))
  }

  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <div>
        <HtmlTooltip
          PopperProps={{
            disablePortal: true,
          }}
          onClose={handleTooltipClose}
          open={open}
          disableFocusListener
          disableHoverListener
          disableTouchListener
          placement="bottom-end"
          title={(
            <Box
              p={1}
              sx={{
                display: 'flex',
                alignItems: 'flex-start',
                flexDirection: 'column',
                justifyContent: 'flex-start',
              }}
            >
              <Typography variant="subtitle2">Bridge Settings</Typography>
              <FormGroup>
                <FormControlLabel
                  control={(
                    <SettingsCheckbox
                      checked={bridgeSettings.useExperimental}
                      onChange={handleUseExperimental}
                    />
                  )}
                  label={<Typography variant="body2">Use experimental feature?</Typography>}
                />
                <FormControlLabel
                  sx={{ display: 'none' }}
                  control={(
                    <SettingsCheckbox
                      checked={bridgeSettings.safeMax}
                      onChange={handleSafeMax}
                    />
                  )}
                  label={<Typography variant="body2">Safe max</Typography>}
                />
              </FormGroup>
            </Box>
          )}
        >
          <IconButton
            aria-label="settings"
            onClick={handleTooltipOpen}
            color="secondary"
          >
            <SettingsIcon />
          </IconButton>
        </HtmlTooltip>
      </div>
    </ClickAwayListener>
  )
}

export default BridgeSettingsButton
