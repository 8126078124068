import {
  Ether, MATIC, BNB, OLT,
} from '../entities'
import { ChainData } from './types'

export const supportedChains: ChainData[] = [
  // mainnets
  {
    name: 'Ethereum',
    shortName: 'eth',
    chain: 'ETH',
    network: 'ethereum',
    chainId: 1,
    networkId: '1',
    rpcURL: 'https://speedy-nodes-nyc.moralis.io/6be9c674e3a0ba73d6ba649d/eth/mainnet',
    explorerURL: 'https://etherscan.io',
    isTest: false,
    nativeCurrency: new Ether(1),
    defaultTargetChainId: 311752642,
    bridgeAddress: '0x337882C0f263D457704Bd52c5741d43F71F316c0',
  },
  {
    name: 'OneLedger',
    shortName: 'olt',
    chain: 'OLT',
    network: 'oneledger',
    chainId: 311752642,
    networkId: '311752642',
    rpcURL: 'https://mainnet-rpc.oneledger.network',
    explorerURL: 'https://mainnet-explorer.oneledger.network',
    isTest: false,
    nativeCurrency: new OLT(311752642),
    defaultTargetChainId: 1,
    bridgeAddress: '0xE9cB771A919ABe70612af5639E6b9c441688C600',
  },
  {
    name: 'BSC',
    shortName: 'bsc',
    chain: 'BSC',
    network: 'bsc',
    chainId: 56,
    networkId: '56',
    rpcURL: 'https://speedy-nodes-nyc.moralis.io/6be9c674e3a0ba73d6ba649d/bsc/mainnet',
    explorerURL: 'https://bscscan.com',
    isTest: false,
    nativeCurrency: new BNB(56),
    defaultTargetChainId: 311752642,
    bridgeAddress: '0x309bdec69FB76861192bFD56dbEC1212a4D3B191',
  },
  {
    name: 'Polygon',
    shortName: 'matic',
    chain: 'MATIC',
    network: 'matic',
    chainId: 137,
    networkId: '137',
    rpcURL: 'https://speedy-nodes-nyc.moralis.io/6be9c674e3a0ba73d6ba649d/polygon/mainnet',
    explorerURL: 'https://polygonscan.com',
    isTest: false,
    nativeCurrency: new MATIC(137),
    defaultTargetChainId: 311752642,
    bridgeAddress: '0x51Bf2FeAf9F72a546e86Bb8ce44705A55Ca881CA',
  },
  // testnets
  {
    name: 'Ropsten',
    shortName: 'rop',
    chain: 'ETH',
    network: 'ropsten',
    chainId: 3,
    networkId: '3',
    rpcURL: 'https://speedy-nodes-nyc.moralis.io/6be9c674e3a0ba73d6ba649d/eth/ropsten',
    explorerURL: 'https://ropsten.etherscan.io',
    isTest: true,
    nativeCurrency: new Ether(3),
    defaultTargetChainId: 4216137055,
    bridgeAddress: '0x319c42009f309e38501b52F4DBd317B927450883',
  },
  {
    name: 'Frankenstein',
    shortName: 'frank',
    chain: 'OLT',
    network: 'frankenstein',
    chainId: 4216137055,
    networkId: '4216137055',
    rpcURL: 'https://frankenstein-rpc.oneledger.network',
    explorerURL: 'https://frankenstein-explorer.oneledger.network',
    isTest: true,
    nativeCurrency: new OLT(4216137055),
    defaultTargetChainId: 3,
    bridgeAddress: '0x83Bc71C069774356469D1c79b43E346bf160757b',
  },
  {
    name: 'BSC Testnet',
    shortName: 'bsc_testnet',
    chain: 'BSC',
    network: 'bsc_testnet',
    chainId: 97,
    networkId: '97',
    rpcURL: 'https://speedy-nodes-nyc.moralis.io/6be9c674e3a0ba73d6ba649d/bsc/testnet',
    explorerURL: 'https://testnet.bscscan.com',
    isTest: true,
    nativeCurrency: new BNB(97),
    defaultTargetChainId: 4216137055,
    bridgeAddress: '0x3BE0E4a86545F123d8b46eECecCc205BbE57Eb99',
  },
  {
    name: 'Mumbai',
    shortName: 'mumbai',
    chain: 'MATIC',
    network: 'mumbai',
    chainId: 80001,
    networkId: '80001',
    rpcURL: 'https://speedy-nodes-nyc.moralis.io/6be9c674e3a0ba73d6ba649d/polygon/mumbai',
    explorerURL: 'https://mumbai.polygonscan.com',
    isTest: true,
    nativeCurrency: new MATIC(80001),
    defaultTargetChainId: 4216137055,
    bridgeAddress: '0x99d4Ab6632E703137b59a92169850e554cAAdEFF',
  },
]
