import { createApi, fakeBaseQuery } from '@reduxjs/toolkit/query/react'

import { ethers } from 'ethers'
import { Web3Provider, Provider } from '@ethersproject/providers'
import { providers } from '@0xsequence/multicall'

import { getContract, timeout } from '../../helpers/utilities'

interface Web3Error {
  code?: string
  message?: string
}

interface RpcCall {
  readonly address?: string
  readonly abi?: ethers.ContractInterface
  readonly method: string
  readonly params?: Array<unknown>
}

interface Web3Request<T> {
  readonly provider?: Web3Provider
  readonly request: T
}

interface ExtraOptions {
  timeout: number
}

export const rpcCall = async (provider: Provider, {
  address, abi, method, params = [],
}: RpcCall, extraOptions: ExtraOptions): Promise<unknown> => {
  const multiCallProvider = (provider instanceof providers.MulticallProvider)
    ? provider
    : new providers.MulticallProvider(provider)
  try {
    let future: Promise<unknown>
    if (address && abi) {
      const contract = getContract(address, abi, multiCallProvider)
      future = contract.callStatic[method]?.(...params)
    } else {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      future = (multiCallProvider as { [index: string]: any })[method](...params)
    }
    const result = await timeout(future, extraOptions.timeout)
    return result
  } catch (error) {
    const web3Error = (typeof error !== undefined) ? (error as Web3Error) : undefined
    console.log('web3 rpcCall ERROR -', web3Error)
    return undefined
  }
}

export const rpcCallBatch = async (
  provider: Provider,
  requests: Array<RpcCall>,
  extraOptions: ExtraOptions,
): Promise<Array<unknown>> => {
  const multiCallProvider = new providers.MulticallProvider(provider)
  return Promise.all(
    requests.map((request) => rpcCall(multiCallProvider, request, extraOptions)),
  )
}

const web3ApiSlice = createApi({
  reducerPath: 'web3Api',
  baseQuery: fakeBaseQuery(),
  tagTypes: ['Network', 'Method', 'Contract', 'Result'],
  endpoints: (builder) => ({
    web3Request: builder.query<unknown, Web3Request<RpcCall | Array<RpcCall>>>({
      queryFn: async ({ provider, request }, baseQuery, extraOptions: ExtraOptions) => {
        if (!provider) {
          return { data: undefined }
        }
        if (!Array.isArray(request)) {
          const result = await rpcCall(provider, request, extraOptions)
          return { data: result }
        }

        const results = await rpcCallBatch(provider, request, extraOptions)
        return { data: results }
      },
      extraOptions: {
        timeout: 15_000,
      } as ExtraOptions,
    }),
  }),
})

export const {
  useWeb3RequestQuery,
  useLazyWeb3RequestQuery,
} = web3ApiSlice

export default web3ApiSlice
