import { ethers } from 'ethers'
import invariant from 'tiny-invariant'
import { BaseCurrency } from './baseCurrency'
import { Currency } from './currency'
import { Token } from './token'
import { TokenLink, TokenLinkMap } from './types'

/**
 * Represents an ERC20 token with a unique address, some metadata and link between bridge token
 */
export class BridgeToken extends BaseCurrency {
  public get isNative(): boolean {
    return this.address === ethers.constants.AddressZero
  }

  public readonly isToken: boolean = true

  public readonly isBridged: boolean = true

  /**
    * The list of tokens which could be bridged with current currency
  */
  private linkMap: TokenLinkMap = {}

  public addLink(chainId: number, tokenLink: TokenLink): void {
    if (this.linkMap[chainId]) return

    this.linkMap[chainId] = tokenLink
  }

  public getBridged(chainId?: number): TokenLink | undefined {
    if (!chainId) return undefined

    return this.linkMap[chainId]
  }

  /**
   * Returns true if the two tokens are equivalent, i.e. have the same chainId and address.
   * @param other other token to compare
   */
  public equals(other: Currency): boolean {
    return other.isToken && this.chainId === other.chainId && this.address.toLowerCase() === other.address.toLowerCase()
  }

  /**
   * Returns true if the address of this token sorts before the address of the other token
   * @param other other token to compare
   * @throws if the tokens have the same address
   * @throws if the tokens are on different chains
   */
  public sortsBefore(other: BridgeToken): boolean {
    invariant(this.chainId === other.chainId, 'CHAIN_IDS')
    invariant(this.address !== other.address, 'ADDRESSES')
    return this.address.toLowerCase() < other.address.toLowerCase()
  }

  /**
   * Return this token, which does not need to be wrapped
   */
  public get wrapped(): Token {
    return this
  }
}
