import { useState, useEffect } from 'react'

import { useTheme, Button } from '@mui/material'
import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import SwapVertIcon from '@mui/icons-material/SwapVert'
import Grid from '@mui/material/Grid'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'

import BridgeInput from '../inputs/BridgeInput'
import {
  BridgeDirectionName, setDualDirection, swapDirection,
} from '../../features/bridge/bridgeSlice'
import { useAppSelector, useAppDispatch } from '../../app/hooks'

import BridgeTransferButton from '../buttons/BridgeTransferButton'
import useFetchBridgeCurrencies from '../../hooks/useFetchBridgeCurrencies'
import { getChainData } from '../../helpers/utilities'
import BridgeSettingsButton from '../buttons/BridgeSettingsButton'
import SmartConnectorWrapperButton from '../buttons/SmartConnectorWrapperButton'

const FormBox = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(4),
  textAlign: 'center',
  color: theme.palette.text.secondary,
  borderRadius: theme.spacing(2),
  boxShadow: '#33333333 0px 4px 21px',
  maxWidth: 500,
  width: 'calc(100vw - 1.8rem)',
  margin: theme.spacing(2),
}))

const WarningPaper = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  textAlign: 'center',
  color: '#2e7a8a',
  border: 'solid 1px #2e7a8a',
  borderRadius: theme.spacing(2),
  boxShadow: '#33333333 0px 4px 21px',
  maxWidth: 500,
  width: 'calc(100vw - 1.8rem)',
  margin: theme.spacing(2),
  backgroundColor: '#5ef5cf',
  padding: theme.spacing(2),
  '& p': {
    fontSize: '.75rem',
  },
}))

const Bridge = (): JSX.Element => {
  const theme = useTheme()
  const [initialized, setInitialized] = useState(false)
  const dispatch = useAppDispatch()
  const wallet = useAppSelector((state) => state.wallet)
  const {
    getDefaultCurrency, getLinkCurrency, isLoading,
  } = useFetchBridgeCurrencies()
  const chainData = getChainData(wallet.chainId)

  useEffect(() => {
    if (
      !chainData
      || !wallet.isAuthorized
      || isLoading
      || initialized
    ) return

    const sourceChainId = chainData.chainId
    const sourceCurrency = getDefaultCurrency(sourceChainId)

    const targetChainId = chainData.defaultTargetChainId
    const targetCurrency = getLinkCurrency(sourceChainId, targetChainId, sourceCurrency?.address)

    dispatch(setDualDirection({
      [BridgeDirectionName.SOURCE]: {
        chainId: sourceChainId,
        currency: sourceCurrency,
      },
      [BridgeDirectionName.TARGET]: {
        chainId: targetChainId,
        currency: targetCurrency,
      },
    }))
    setInitialized(true)
  }, [initialized, wallet, getDefaultCurrency, getLinkCurrency, chainData, isLoading, dispatch])

  const onSwapDirection = () => {
    dispatch(swapDirection())
  }

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: 'calc(100vh - 100px)',
        flexDirection: 'column',
      }}
    >
      <WarningPaper>
        <Grid container>
          <Grid item xs={1}>
            <InfoOutlinedIcon sx={{ width: 0.5, height: 0.5 }} />
          </Grid>
          <Grid item xs={11}>
            <Typography
              align="left"
            >
              Syndicate Bridge is an early stage product,
              we are NOT responsible for any loss during the transfers.
            </Typography>
            <Typography align="left" sx={{ fontWeight: 600 }}>
              PLEASE USE AT YOUR OWN RISK.
            </Typography>
          </Grid>
        </Grid>
      </WarningPaper>
      <FormBox>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginBottom: 4,
          }}
        >
          <Typography variant="h6" sx={{ color: theme.palette.secondary.main }}>Bridge</Typography>
          {wallet.isAuthorized ? (
            <BridgeSettingsButton />
          ) : (
            <Typography variant="h6" sx={{ color: theme.palette.secondary.main }}>
              Not connected
            </Typography>
          )}
        </Box>
        <BridgeInput
          labelName="From"
          direction={BridgeDirectionName.SOURCE}
        />
        <Box p={1}>
          <Button
            aria-label="swap"
            color={theme.palette.mode === 'light' ? 'warning' : 'secondary'}
            onClick={onSwapDirection}
            endIcon={<SwapVertIcon />}
          >
            Switch
          </Button>
        </Box>
        <BridgeInput
          labelName="To"
          direction={BridgeDirectionName.TARGET}
        />
        <Box sx={{ marginTop: 4 }}>
          <SmartConnectorWrapperButton variant="outlined" color="secondary" size="large">
            <BridgeTransferButton />
          </SmartConnectorWrapperButton>
        </Box>
      </FormBox>
    </Box>
  )
}

export default Bridge
