import { ethers, BigNumber } from 'ethers'
import { Web3Provider } from '@ethersproject/providers'

import { Currency } from '../entities'
import { useWeb3RequestQuery } from '../features/api/web3ApiSlice'
import { getChainData } from '../helpers/utilities'

import IERC20ABI from '../abi/IERC20.abi.json'

interface CheckAllowanceData {
  provider?: Web3Provider
  currency?: Currency
  account?: string
}

interface AllowanceAction {
  allowance?: BigNumber
  isSkipped: boolean
  isMintable: boolean
  isRequired: boolean
  isSuccess: boolean
  isError: boolean
  isFetching: boolean
  isLoading: boolean
}

// TODO: Add DOMAIN_SEPARATOR check to prevent redundant transaction
const useCheckAllowance = ({
  provider, currency, account,
}: CheckAllowanceData): AllowanceAction => {
  const chain = getChainData(currency?.chainId)
  const skip = (
    !currency
    || currency?.isNative
    || !provider
    || !chain
    || (!currency?.isNative && !account)
  )

  const { data: storageData, ...storageQueryData } = useWeb3RequestQuery({
    provider,
    request: {
      method: 'getStorageAt',
      params: [currency?.address, '0x360894a13ba1a3210667c828492db98dca3e2076cc3735a920a3ca505d382bbc', 'latest'],
    },
  }, {
    skip,
  })

  const implementation = (storageData) ? (storageData as string).substring(26) : ''

  const { data: allowanceData, ...allowanceQueryData } = useWeb3RequestQuery({
    provider,
    request: [
      {
        method: 'getCode',
        params: [implementation],
      },
      {
        address: currency?.address,
        abi: IERC20ABI,
        method: 'allowance',
        params: [account, chain?.bridgeAddress],
      },
    ],
  }, {
    skip: !implementation,
    pollingInterval: 15_000,
  })

  const [code, allowance] = (allowanceData)
    ? (allowanceData as [string | undefined, BigNumber | undefined])
    : [undefined, undefined]

  const funcSig = ethers.utils.keccak256(ethers.utils.toUtf8Bytes('mint(address,uint256)')).substring(2, 10)
  const isMintable = code ? (code as string).indexOf(funcSig) > 0 : false

  return {
    allowance,
    isMintable,
    isSkipped: skip || !implementation,
    isRequired: !currency?.isNative && !isMintable,
    isSuccess: storageQueryData.isSuccess && allowanceQueryData.isSuccess,
    isError: storageQueryData.isError || allowanceQueryData.isError,
    isFetching: storageQueryData.isFetching || allowanceQueryData.isFetching,
    isLoading: storageQueryData.isLoading || allowanceQueryData.isLoading,
  }
}

export default useCheckAllowance
