import { cloneElement } from 'react'

import { UnsupportedChainIdError, useWeb3React } from '@web3-react/core'
import { Web3Provider } from '@ethersproject/providers'

import { useAppSelector } from '../../app/hooks'
import ConnectButton from './ConnectButton'
import ColorizedButton from './ColorizedButton'

interface DappWrapperProps {
  children: JSX.Element
  size?: 'small' | 'medium' | 'large'
  variant?: 'text' | 'outlined' | 'contained'
  color?: 'primary' | 'secondary' |
  'inherit' | 'success' | 'error' | 'info' | 'warning'
}

const SmartConnectorWrapperButton = ({ children, ...otherProps }: DappWrapperProps): JSX.Element => {
  const wallet = useAppSelector((state) => state.wallet)
  const { error } = useWeb3React<Web3Provider>()
  const isUnsupportedChainIdError = error instanceof UnsupportedChainIdError

  if (isUnsupportedChainIdError) {
    return (
      <ColorizedButton
        fullWidth
        disabled
        {...otherProps}
      >
        Unsupported network
      </ColorizedButton>
    )
  }
  if (!wallet.isAuthorized) {
    return <ConnectButton {...otherProps} />
  }
  return cloneElement(children, { ...otherProps })
}

export default SmartConnectorWrapperButton
