import { ReactNode, useEffect } from 'react'

import { useWeb3React, Web3ReactProvider } from '@web3-react/core'
import {
  Web3Provider,
} from '@ethersproject/providers'

import {
  getLibrary, Web3SourceProvider, Web3TargetProvider,
} from './utils'
import { BridgeDirectionName } from '../features/bridge/bridgeSlice'
import { useAppSelector } from '../app/hooks'
import { connectorsByName } from '../helpers/connectors'

interface Web3BridgeDirectionInjectorProps {
  direction: BridgeDirectionName
  children?: ReactNode
}

const Web3BridgeDirectionInjector = ({ direction, children }: Web3BridgeDirectionInjectorProps): JSX.Element => {
  const directionChainId = useAppSelector((state) => state.bridge.directions[direction].chainId)

  const network = connectorsByName[direction]

  const {
    chainId, connector, active, activate, deactivate,
  } = useWeb3React<Web3Provider>(direction)

  useEffect(() => {
    if (chainId && directionChainId && chainId !== directionChainId) {
      console.log(`${direction} network swapped, changing "${chainId}" -> "${directionChainId}"...`)
      deactivate()
    }
  }, [directionChainId, chainId, connector, deactivate, direction])

  useEffect(() => {
    if (!directionChainId || (active && chainId)) return

    (async () => {
      console.log(`Activating ${direction} network...`)
      network.changeChainId(directionChainId)
      await activate(network, undefined, true)
    })()
  }, [directionChainId, active, activate, direction, chainId, network])

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {children}
    </>
  )
}

interface Web3BridgeNetworkInjectorProps {
  children?: ReactNode
}

const Web3BridgeNetworkInjector = ({ children }: Web3BridgeNetworkInjectorProps): JSX.Element => (
  <Web3BridgeDirectionInjector direction={BridgeDirectionName.SOURCE}>
    <Web3BridgeDirectionInjector direction={BridgeDirectionName.TARGET}>
      {children}
    </Web3BridgeDirectionInjector>
  </Web3BridgeDirectionInjector>
)

interface ProviderProps {
  children?: ReactNode
}

const BridgeProvider = ({ children }: ProviderProps): JSX.Element => (
  <Web3ReactProvider getLibrary={getLibrary}>
    <Web3SourceProvider getLibrary={getLibrary}>
      <Web3TargetProvider getLibrary={getLibrary}>
        <Web3BridgeNetworkInjector>
          {children}
        </Web3BridgeNetworkInjector>
      </Web3TargetProvider>
    </Web3SourceProvider>
  </Web3ReactProvider>
)

export default BridgeProvider
