import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

const API_KEY = '2cedecc4209201cfb6493f8c84c4adf725354954e144c9ca0d67b4ccbc594e6b'

const priceApiSlice = createApi({
  reducerPath: 'priceApi',
  baseQuery: fetchBaseQuery({
    baseUrl: 'https://min-api.cryptocompare.com',
    prepareHeaders: (headers) => {
      headers.set('Authorization', `Apikey ${API_KEY}`)
      return headers
    },
  }),
  endpoints: (builder) => ({
    fetchPrice: builder.query<number, string>({
      query: (symbol) => ({
        url: `/data/price?fsym=${symbol}&tsyms=USD`,
        validateStatus: (response, result) => response.status === 200 && result?.Response !== 'Error',
      }),
      transformResponse: (response) => {
        const data = <{ [symbol: string]: number }>response
        return data[Object.keys(data)[0]]
      },
    }),
  }),
})

export const {
  useFetchPriceQuery,
} = priceApiSlice

export default priceApiSlice
