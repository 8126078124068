import { Button } from '@mui/material'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import ImgIcon from '../icons/ImgIcon'
import { DEFAULT_TOKEN_SYMBOL } from '../../helpers/constants'
import { getTokenLogoURI } from '../../helpers/utilities'

interface TokenButtonProps {
  symbol?: string
  logoURI?: string
  disabled: boolean
  onClick?(): void
}

const TokenButton = (
  {
    symbol, logoURI, disabled = false, onClick,
  }: TokenButtonProps,
): JSX.Element => (
  <Button
    fullWidth
    color="secondary"
    variant="text"
    startIcon={
      <ImgIcon src={logoURI ?? getTokenLogoURI(DEFAULT_TOKEN_SYMBOL)} />
    }
    endIcon={<KeyboardArrowDownIcon />}
    disabled={disabled}
    sx={{ textTransform: 'none' }}
    onClick={onClick}
  >
    {symbol ?? DEFAULT_TOKEN_SYMBOL}
  </Button>
)

export default TokenButton
