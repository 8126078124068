import { useMemo } from 'react'

import { Web3Provider } from '@ethersproject/providers'
import { BigNumber } from 'ethers'

import {
  useWeb3RequestQuery,
} from '../features/api/web3ApiSlice'
import { Currency } from '../entities'
import IERC20ABI from '../abi/IERC20.abi.json'

interface FetchInfo {
  address: string
  currency: Currency
}

interface FetchBalanceProps {
  provider: Web3Provider
  fetchInfo: FetchInfo | Array<FetchInfo>
  pollingInterval?: number
}

interface FetchBalanceAction {
  data?: BigNumber | Array<BigNumber | undefined>
  isSuccess: boolean
  isError: boolean
  isFetching: boolean
  isLoading: boolean
}

const DefaultPollingInterval = 30_000

const prepareRequest = ({ address, currency }: FetchInfo) => ((currency.isNative) ? {
  method: 'getBalance',
  params: [address, 'latest'],
} : {
  address: currency.address,
  abi: IERC20ABI,
  method: 'balanceOf',
  params: [address],
})

const useFetchBalancePerCurrencies = ({
  provider, fetchInfo, pollingInterval = DefaultPollingInterval,
}: FetchBalanceProps): FetchBalanceAction => {
  const request = useMemo(() => (Array.isArray(fetchInfo)
    ? fetchInfo.map((info) => prepareRequest(info))
    : prepareRequest(fetchInfo)), [fetchInfo])

  const { data, ...queryData } = useWeb3RequestQuery({
    provider,
    request,
  }, {
    pollingInterval,
  })

  const response = useMemo(() => {
    if (!data) return undefined

    return !Array.isArray(data) ? BigNumber.from(data) : data.map((value) => (
      value && BigNumber.from(value)
    )) as Array<BigNumber | undefined>
  }, [data])

  return {
    data: response,
    ...queryData,
  }
}

export default useFetchBalancePerCurrencies
