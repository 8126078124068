import axios, { AxiosError } from 'axios'
import { createApi, fakeBaseQuery } from '@reduxjs/toolkit/query/react'

import { SYNDICATE_BOWL_BASE_URI } from '../../helpers/constants'

enum ResponseMsgStatus {
  OK = 'OK',
  NOTOK = 'NOTOK',
}

interface CosignerRequest {
  txHash: string
  sourceChainId: number
  targetChainId: number
}

export interface SignedData {
  data: string
  signatures: Array<string>
}

interface CosignerResponse {
  status: ResponseMsgStatus
  msg?: string
  results?: Array<SignedData>
}

const cosignerApiSlice = createApi({
  reducerPath: 'cosignerApi',
  baseQuery: fakeBaseQuery(),
  endpoints: (builder) => ({
    cosignerSignatures: builder.query<SignedData[], CosignerRequest>({
      queryFn: async (request) => {
        try {
          const result = await axios.post<CosignerResponse>(`${SYNDICATE_BOWL_BASE_URI}/cosign`, {
            tx_hash: request.txHash,
            source_chain_id: request.sourceChainId,
            target_chain_id: request.targetChainId,
          })
          if (result.data.status === ResponseMsgStatus.OK) {
            return { data: result.data.results }
          }
          return {
            error: { data: result.data.msg },
          }
        } catch (axiosError) {
          const err = axiosError as AxiosError
          return {
            error: { status: err.response?.status, data: err.response?.data },
          }
        }
      },
    }),
  }),
})

export const {
  useCosignerSignaturesQuery,
} = cosignerApiSlice

export default cosignerApiSlice
