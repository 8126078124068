import { InjectedConnector } from '@web3-react/injected-connector'
import { NetworkConnector } from '@web3-react/network-connector'

import { supportedChains } from './chains'
import { DEFAULT_CHAIN_ID } from './constants'

const supportedChainIds = supportedChains.map((chain) => chain.chainId)

export const injected = new InjectedConnector({ supportedChainIds })

export const sourceNetwork = new NetworkConnector({
  urls: supportedChains.reduce((urls, chain) => {
    urls[chain.chainId] = chain.rpcURL
    return urls
  }, {} as { [chainId: number]: string }),
  defaultChainId: DEFAULT_CHAIN_ID,
})

export const targetNetwork = new NetworkConnector({
  urls: supportedChains.reduce((urls, chain) => {
    urls[chain.chainId] = chain.rpcURL
    return urls
  }, {} as { [chainId: number]: string }),
  defaultChainId: supportedChains.filter((chain) => chain.chainId === DEFAULT_CHAIN_ID)[0].defaultTargetChainId,
})

export const refetcher = new NetworkConnector({
  urls: supportedChains.reduce((urls, chain) => {
    urls[chain.chainId] = chain.rpcURL
    return urls
  }, {} as { [chainId: number]: string }),
  defaultChainId: DEFAULT_CHAIN_ID,
})

export enum ConnectorNames {
  Injected = 'Injected',
  SourceNetwork = 'SourceNetwork',
  TargetNetwork = 'TargetNetwork',
  Refetcher = 'Refetcher'
}

export const connectorsByName = {
  [ConnectorNames.Injected]: injected,
  [ConnectorNames.SourceNetwork]: sourceNetwork,
  [ConnectorNames.TargetNetwork]: targetNetwork,
  [ConnectorNames.Refetcher]: refetcher,
}
