import { forwardRef } from 'react'
import { NavLink as RouterLink } from 'react-router-dom'

import { useTheme } from '@mui/material'
import Button from '@mui/material/Button'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'

interface RouteButtonProps {
  name: string;
  disabled: boolean;
  path: string;
  onClick?(): void
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const NavLinkMui = forwardRef<any, any>((props, ref) => {
  const theme = useTheme()
  const { ...otherProps } = props
  return (
    <RouterLink
      {...otherProps}
      ref={ref}
      role={undefined}
      style={({ isActive }) => ((isActive) ? {
        color: (theme.palette.mode === 'light') ? 'white' : 'white',
        textDecoration: 'none',
      } : null)}
    />
  )
})

const RouteButton = ({
  name, disabled, path, onClick,
}: RouteButtonProps): JSX.Element | null => {
  if (path === '/' || disabled) return null

  const dprops = { sx: { my: 2, display: 'flex', fontWeight: 500 } }

  if (path.startsWith('http')) {
    const bprops = { sx: { ...dprops.sx, alignItems: 'flex-start', '& span': { margin: 0 } } }
    return (
      <Button
        color="secondary"
        key={name}
        target="_blank"
        rel="noopener noreferrer"
        href={path}
        endIcon={<OpenInNewIcon sx={{ width: 8, height: 8 }} />}
        {...bprops}
      >
        {name}
      </Button>
    )
  }
  const bprops = (disabled) ? {
    ...dprops,
    disableRipple: true,
    disableFocusRipple: true,
    disableElevation: true,
  } : {
    ...dprops,
    component: NavLinkMui,
    to: path,
  }

  return (
    <Button
      color="secondary"
      key={name}
      onClick={onClick}
      {...bprops}
    >
      {name}
    </Button>
  )
}

export default RouteButton
