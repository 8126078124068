import React, {
  memo, useState, useEffect, useMemo, useCallback,
} from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'

import { CircularProgress, useTheme } from '@mui/material'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import Typography from '@mui/material/Typography'
import Paper from '@mui/material/Paper'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Link from '@mui/material/Link'
import ButtonGroup from '@mui/material/ButtonGroup'
import LaunchIcon from '@mui/icons-material/Launch'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'

import { useWeb3React } from '@web3-react/core'
import { Web3Provider } from '@ethersproject/providers'

import { useAppDispatch, useAppSelector } from '../../app/hooks'
import { closeModal, ModalType } from '../../features/modal/modalSlice'
import { deauthorize } from '../../features/wallet/walletSlice'
import useFetchBridgeCurrencies from '../../hooks/useFetchBridgeCurrencies'

import { getChainData, getShortAddress, prettyFormat } from '../../helpers/utilities'
import BootstrapDialogTitle from './BootstrapDialogTitle'
import {
  clearAllTxs,
  CrossChainTransferStatus,
  CrossChainTx,
  getAccountTransactions,
  isBridgeTx,
  LocalTx,
  LocalTxStatus,
} from '../../features/wallet/transactionSlice'
import { ChainData } from '../../helpers/types'
import UnfinishedBridgeTxButton from '../buttons/UnfinishedBridgeTxButton'

const CopyAddressLink = memo(({ address }: { address: string }): JSX.Element => {
  const [copied, setCopied] = useState(false)

  useEffect(() => {
    if (!copied) return undefined

    const id = setTimeout(() => setCopied(false), 500)
    return () => {
      clearTimeout(id)
    }
  }, [copied])

  const { text, startIcon } = useMemo(() => ((copied) ? {
    text: 'Copied',
    startIcon: <CheckCircleOutlineIcon sx={{ color: 'green' }} />,
  } : {
    text: 'Copy address',
    startIcon: <ContentCopyIcon />,
  }), [copied])

  return (
    <CopyToClipboard text={address} onCopy={() => setCopied(true)}>
      <Button
        color="secondary"
        startIcon={startIcon}
        sx={{
          textTransform: 'none',
        }}
      >
        {text}
      </Button>
    </CopyToClipboard>
  )
})
CopyAddressLink.displayName = 'CopyAddressLink'

const TxLink = ({ chain, txHash }: { chain: ChainData, txHash?: string }): JSX.Element => {
  if (!txHash) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{chain.name}</>
  }

  return (
    <Link
      color="secondary"
      sx={{ fontSize: 12 }}
      target="_blank"
      rel="noopener noreferrer"
      href={txHash && `${chain.explorerURL}/tx/${txHash}`}
    >
      {chain.name}
    </Link>
  )
}

const BridgeTxBox = ({ tx }: { tx: CrossChainTx }): JSX.Element | null => {
  const depositChain = useMemo(() => getChainData(tx.deposit.currency.chainId), [tx.deposit.currency.chainId])
  const withdrawChain = useMemo(() => getChainData(tx.withdraw.currency.chainId), [tx.withdraw.currency.chainId])

  const statusBlock = useMemo(() => {
    switch (tx.status) {
      case CrossChainTransferStatus.DONE:
        return <CheckCircleOutlineIcon sx={{ color: 'green', width: 20, height: 20 }} />
      default:
        return <CircularProgress size={18} color="secondary" />
    }
  }, [tx.status])

  const resumeBlock = useMemo(() => {
    switch (tx.status) {
      case CrossChainTransferStatus.DONE:
        return null
      default:
        return (
          <UnfinishedBridgeTxButton
            id={tx.id}
            onlyIcon={false}
            sx={{
              fontSize: 10,
              height: 24,
              minWidth: 72,
            }}
          />
        )
    }
  }, [tx.status, tx.id])
  if (!depositChain || !withdrawChain) return null

  return (
    <Grid container spacing={1} sx={{ paddingBottom: 1 }}>
      <Grid
        item
        xs={10}
        sx={{
          display: 'flex', justifyContent: 'flex-start', alignItems: 'top', flexDirection: 'row',
        }}
      >
        <Typography sx={{ fontSize: 12, marginBottom: 0.5 }}>
          {`Transfer ${prettyFormat(tx.value, tx.deposit.currency.decimals)} ${tx.deposit.currency.symbol} from `}
          <TxLink chain={depositChain} txHash={tx.deposit.txHash} />
          {' to '}
          <TxLink chain={withdrawChain} txHash={tx.withdraw.txHash} />
        </Typography>
        {resumeBlock}
      </Grid>
      <Grid item xs={2} sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'top' }}>
        {statusBlock}
      </Grid>
    </Grid>
  )
}

const LocalTxBox = ({ tx }: { tx: LocalTx }): JSX.Element | null => {
  const chain = useMemo(() => getChainData(tx.chainId), [tx.chainId])

  const { getCurrency } = useFetchBridgeCurrencies()

  const statusBlock = useMemo(() => {
    switch (tx.status) {
      case LocalTxStatus.FAIL:
        return <ErrorOutlineIcon sx={{ color: 'red', width: 20, height: 20 }} />
      case LocalTxStatus.SUCCESS:
        return <CheckCircleOutlineIcon sx={{ color: 'green', width: 20, height: 20 }} />
      case LocalTxStatus.PENDING:
      default:
        return <CircularProgress size={18} color="secondary" />
    }
  }, [tx.status])

  const textBlock = useMemo(() => {
    if (!chain) return null

    switch (tx.method) {
      case 'approve': {
        const currency = getCurrency(tx.chainId, tx.to)
        return (
          <Typography sx={{ fontSize: 12, marginBottom: 0.5 }}>
            {`Approve ${currency?.symbol} on `}
            <TxLink chain={chain} txHash={tx.txHash} />
          </Typography>
        )
      }
      default:
        return null
    }
  }, [chain, getCurrency, tx.chainId, tx.method, tx.to, tx.txHash])

  return (
    <Grid container spacing={1} sx={{ paddingBottom: 1 }}>
      <Grid
        item
        xs={10}
        sx={{
          display: 'flex', justifyContent: 'flex-start', alignItems: 'top', flexDirection: 'row',
        }}
      >
        {textBlock}
      </Grid>
      <Grid item xs={2} sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'top' }}>
        {statusBlock}
      </Grid>
    </Grid>
  )
}

const TransactionContent = (): JSX.Element => {
  const dispatch = useAppDispatch()
  const txs = useAppSelector(getAccountTransactions)
  const onClearAll = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault()
    dispatch(clearAllTxs())
  }
  return (
    <Grid container spacing={3} sx={{ p: 1, paddingLeft: 2, paddingRight: 2 }}>
      {!txs.length ? (
        <Grid item xs={12} style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography>Your transactions will appear here...</Typography>
        </Grid>
      ) : (
        <>
          <Grid item xs={12} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography>Recent Transactions</Typography>
            <Link href="/#" onClick={onClearAll} sx={{ fontSize: 12 }} color="secondary">(clear all)</Link>
          </Grid>
          <Grid item xs={12}>
            {txs.map((tx) => (
              isBridgeTx(tx)
                ? <BridgeTxBox key={tx.id} tx={tx} />
                : <LocalTxBox key={tx.id} tx={tx} />
            ))}
          </Grid>
        </>
      )}
    </Grid>
  )
}

const WalletModal = (): JSX.Element => {
  const theme = useTheme()
  const dispatch = useAppDispatch()
  const open = useAppSelector((state) => state.modal[ModalType.WALLET])

  const { account, chainId } = useAppSelector((state) => state.wallet)
  const { deactivate } = useWeb3React<Web3Provider>()

  const chain = getChainData(chainId)

  const handleClose = useCallback(() => dispatch(closeModal()), [dispatch])
  const handleDisconnect = () => {
    dispatch(deauthorize(true))
    deactivate()
    handleClose()
  }

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="wallet-dialog-title"
      open={open}
      fullWidth
      maxWidth="xs"
    >
      <BootstrapDialogTitle id="wallet-dialog-title" onClose={handleClose}>
        Account
      </BootstrapDialogTitle>
      <DialogContent>
        <Paper
          variant="outlined"
          sx={{
            p: 1,
            backgroundColor: 'unset',
          }}
        >
          {account && chain && (
            <Grid container spacing={2} sx={{ p: 2 }}>
              <Grid item xs={12}>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <Typography gutterBottom sx={{ fontSize: 14 }}>Connected with MetaMask</Typography>
                  <ButtonGroup
                    variant="outlined"
                    color="warning"
                    size="small"
                    sx={{
                      p: 0,
                      marginLeft: 1,
                    }}
                  >
                    <Button
                      onClick={handleDisconnect}
                    >
                      Disconnect
                    </Button>
                  </ButtonGroup>
                </Box>
                <Typography
                  color="secondary"
                  sx={{ fontWeight: 600 }}
                >
                  {getShortAddress(account)}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <CopyAddressLink address={account} />
                <Button
                  color="secondary"
                  target="_blank"
                  rel="noopener noreferrer"
                  startIcon={<LaunchIcon />}
                  href={`${chain.explorerURL}/address/${account}`}
                  sx={{
                    textTransform: 'none',
                  }}
                >
                  View on Explorer
                </Button>
              </Grid>
            </Grid>
          )}
        </Paper>
      </DialogContent>
      <DialogContent
        sx={{
          backgroundColor: (theme.palette.mode === 'light') ? '#f7f8fa' : '#1b1b1b',
          maxHeight: 300,
        }}
      >
        <TransactionContent />
      </DialogContent>
    </Dialog>
  )
}

export default WalletModal
