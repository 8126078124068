import LoadingButton from '@mui/lab/LoadingButton'

import { Web3Provider } from '@ethersproject/providers'
import { Currency } from '../../entities'
import useFetchMetamaskAPI from '../../hooks/useFetchMetamaskAPI'

interface AddTokenButtonProps {
  provider: Web3Provider
  currency: Currency
}

const AddTokenButton = ({ provider, currency }: AddTokenButtonProps): JSX.Element => {
  const { addToken, isLoading } = useFetchMetamaskAPI(provider)
  return (
    <LoadingButton
      color="secondary"
      size="small"
      loading={isLoading}
      onClick={() => addToken(currency)}
      fullWidth
      sx={{ height: 30, fontSize: '0.7rem' }}
      style={{ textTransform: 'none' }}
    >
      Add to wallet
    </LoadingButton>
  )
}

export default AddTokenButton
