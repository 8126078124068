import { Currency } from '../entities'
import { useFetchPriceQuery } from '../features/api/priceApiSlice'
import { DEFAULT_TOKEN_SYMBOL } from '../helpers/constants'

interface PriceCurrencyAction {
  price: number
  isLoading: boolean
  isError: boolean
  isFetching: boolean
}

const useFetchPriceCurrency = (currency: Currency): PriceCurrencyAction => {
  const symbol = (currency.symbol === `s${DEFAULT_TOKEN_SYMBOL}`) ? DEFAULT_TOKEN_SYMBOL : currency.symbol
  const {
    data, isError, isLoading, isFetching,
  } = useFetchPriceQuery(symbol)

  return {
    price: data || 0,
    isError,
    isLoading,
    isFetching,
  }
}

export default useFetchPriceCurrency
