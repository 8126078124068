import { BaseCurrency } from './baseCurrency'

/**
 * Represents the native currency of the chain on which it resides, e.g.
 */
export abstract class NativeCurrency extends BaseCurrency {
  public readonly isNative: boolean = true

  public readonly isToken: boolean = false

  public readonly isBridged: boolean = false
}
