import { ThemeOptions } from '@mui/material'
import { grey } from '@mui/material/colors'
import darkScrollbar from '@mui/material/darkScrollbar'

const baseThemeOpts: ThemeOptions = {
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 16,
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          borderRadius: 16,
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: 16,
        },
      },
    },
    MuiButtonGroup: {
      styleOverrides: {
        root: {
          borderRadius: 16,
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          borderRadius: 0,
        },
      },
    },
  },
}

export const LightThemeOpts: ThemeOptions = {
  palette: {
    mode: 'light',
    primary: {
      main: '#3b8c9e',
    },
    secondary: {
      main: grey[900],
    },
  },
  components: { ...baseThemeOpts.components },
}

export const DarkThemeOpts: ThemeOptions = {
  palette: {
    mode: 'dark',
    primary: {
      main: grey[900],
    },
    secondary: {
      main: '#5ef5cf',
    },
  },
  components: {
    ...baseThemeOpts.components,
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          ...darkScrollbar(),
          backgroundColor: '#5ef5cf',
          background: 'linear-gradient(0deg, #5ef5cf 0%, #272727 34%, #121212 67%)',
        },
      },
    },
  },
}
