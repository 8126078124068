import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { BigNumber, ethers } from 'ethers'

import { Currency } from '../../entities'

export enum BridgeDirectionName {
  SOURCE = 'SourceNetwork',
  TARGET = 'TargetNetwork',
}

export enum BridgeStatus {
  READY = 1,
  NOT_ENOUGH_BALANCE = 2,
  UNSUPPORTED_DIRECTION = 3,
  REQURED_AMOUNT = 4,
}

export interface BridgeAccountInfo {
  chainId?: number
  currency?: Currency
  // for input value
  value?: BigNumber
}

interface BridgeState {
  // current balance
  balance: BigNumber
  // for modal selection
  selected?: BridgeDirectionName
  // for transfer enabling
  status: BridgeStatus,
  directions: {
    [BridgeDirectionName.SOURCE]: BridgeAccountInfo,
    [BridgeDirectionName.TARGET]: BridgeAccountInfo,
  }
}

interface SetInput {
  direction: BridgeDirectionName
  chainId?: number
  currency?: Currency
}

interface DualInput {
  [direction: string]: {
    chainId?: number
    currency?: Currency
  }
}

const initialState: BridgeState = {
  balance: ethers.constants.Zero,
  selected: undefined,
  status: BridgeStatus.REQURED_AMOUNT,
  directions: {
    [BridgeDirectionName.SOURCE]: {},
    [BridgeDirectionName.TARGET]: {},
  },
}

// should be used internal
const updateStatus = (state: BridgeState) => {
  const source = state.directions[BridgeDirectionName.SOURCE]
  const target = state.directions[BridgeDirectionName.TARGET]

  let statusToUpdate: BridgeStatus | undefined

  if (!source.currency || !target.currency) {
    statusToUpdate = BridgeStatus.UNSUPPORTED_DIRECTION
  } else if (!source.value || source.value.eq(ethers.constants.Zero)) {
    statusToUpdate = BridgeStatus.REQURED_AMOUNT
  } else if (state.balance.lt(source.value)) {
    statusToUpdate = BridgeStatus.NOT_ENOUGH_BALANCE
  } else {
    statusToUpdate = BridgeStatus.READY
  }

  if (statusToUpdate && statusToUpdate !== state.status) {
    state.status = statusToUpdate
  }
}

export const bridgeSlice = createSlice({
  name: 'bridge',
  initialState,
  reducers: {
    setBalance: (state, action: PayloadAction<BigNumber>) => {
      if (!state.balance.eq(action.payload)) {
        state.balance = action.payload
      }
      updateStatus(state)
    },
    setDirection: (state, action: PayloadAction<SetInput>) => {
      const bridgeDirection = state.directions[action.payload.direction]

      bridgeDirection.chainId = action.payload.chainId
      bridgeDirection.currency = action.payload.currency
      updateStatus(state)
    },
    setDualDirection: (state, action: PayloadAction<DualInput>) => {
      const sourceInput = action.payload.SourceNetwork
      const targetInput = action.payload.TargetNetwork

      const sourceDirection = state.directions[BridgeDirectionName.SOURCE]
      const targetDirection = state.directions[BridgeDirectionName.TARGET]

      state.directions = {
        [BridgeDirectionName.SOURCE]: {
          ...sourceDirection,
          currency: sourceInput.currency,
          chainId: sourceInput.chainId || sourceDirection.chainId,
        },
        [BridgeDirectionName.TARGET]: {
          ...targetDirection,
          ...targetInput,
          currency: targetInput.currency,
          chainId: targetInput.chainId || targetDirection.chainId,
        },
      }
      updateStatus(state)
    },
    swapDirection: (state) => {
      const sourceDirection = state.directions[BridgeDirectionName.SOURCE]
      const targetDirection = state.directions[BridgeDirectionName.TARGET]
      state.directions = {
        [BridgeDirectionName.SOURCE]: targetDirection,
        [BridgeDirectionName.TARGET]: sourceDirection,
      }
      updateStatus(state)
    },
    reset: () => initialState,
    setValue: (state, action: PayloadAction<BigNumber | undefined>) => {
      const sourceDirection = state.directions[BridgeDirectionName.SOURCE]
      const targetDirection = state.directions[BridgeDirectionName.TARGET]
      if (
        !action.payload
        || !sourceDirection.value
        || (
          action.payload
          && sourceDirection.value
          && !sourceDirection.value.eq(action.payload)
        )
      ) {
        sourceDirection.value = action.payload
        targetDirection.value = action.payload
      }
      updateStatus(state)
    },
    selectDirection: (state, action: PayloadAction<BridgeDirectionName | undefined>) => {
      state.selected = action.payload
    },
  },
})

export const {
  setDirection, swapDirection, reset,
  setValue, selectDirection, setDualDirection,
  setBalance,
} = bridgeSlice.actions

export default bridgeSlice.reducer
