import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

interface ChainInfo {
  name: string
  chain: string
  network: string
  icon: string
  rpc: string[]
  faucets: string[]
  nativeCurrency: {
    name: string
    symbol: string
    decimals: string
  }
  infoURL: string
  shortName: string
  chainId: number
  networkId: number
  slip44?: number
  ens?: {
    registry: string
  }
  explorers: Array<{
    name: string
    url: string
    standard: string
  }>
}

const chainListApiSlice = createApi({
  reducerPath: 'chainListApi',
  baseQuery: fetchBaseQuery({
    baseUrl: 'https://chainid.network',
  }),
  endpoints: (builder) => ({
    chainList: builder.query<ChainInfo[], void>({
      query: () => ('/chains.json'),
    }),
  }),
})

export const {
  useChainListQuery,
} = chainListApiSlice

export default chainListApiSlice
