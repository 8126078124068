import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'

import MetaMaskIcon from '../../assets/icons/wallets/MetaMaskIcon.png'

interface ButtonProps {
  onClick?(): void
}

const MetaMaskButton = ({ onClick }: ButtonProps): JSX.Element => (
  <Button
    variant="outlined"
    color="secondary"
    fullWidth
    sx={{
      textTransform: 'none',
      justifyContent: 'space-between',
      p: 1,
      paddingLeft: 2,
      paddingRight: 2,
    }}
    size="large"
    onClick={onClick}
  >
    <Typography>MetaMask</Typography>
    <img src={MetaMaskIcon} style={{ height: '20px' }} alt="MetaMask" />
  </Button>
)

export default MetaMaskButton
