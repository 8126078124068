import { useEffect } from 'react'
import { useSnackbar } from 'notistack'
import { Web3Provider, TransactionReceipt } from '@ethersproject/providers'

import { useAppDispatch } from '../app/hooks'
import useFetchTransactions from './useFetchTransactions'

interface WatchData {
  provider?: Web3Provider
  txHash?: string
  confirmations?: number
  onSuccess(receipt: TransactionReceipt, confirmations: number): void
  onError(receipt: TransactionReceipt, confirmations: number): void
}

const useWatchTx = ({
  provider, txHash, confirmations,
  onSuccess, onError,
}: WatchData): void => {
  const { enqueueSnackbar } = useSnackbar()
  const dispatch = useAppDispatch()
  const { data } = useFetchTransactions({
    provider,
    txHashes: (txHash && !confirmations) ? [txHash] : [],
  })

  const receipt = !data ? null : data[0]

  useEffect(() => {
    if (!txHash || !receipt) return

    if (!receipt.status || receipt.status === 0) {
      enqueueSnackbar(`Tx "${receipt.transactionHash}" failed!`, { variant: 'warning' })
      onError(receipt, 1)
      return
    }

    // TODO: Add dynamic checking on confirmations
    onSuccess(receipt, 1)
  }, [txHash, confirmations, dispatch, receipt, enqueueSnackbar, onSuccess, onError])
}

export default useWatchTx
