import { memo } from 'react'
import * as Sentry from '@sentry/react'

import Button from '@mui/material/Button'
import { useTheme } from '@mui/material'
import { SxProps, Theme } from '@mui/material/styles'
import PriorityHighIcon from '@mui/icons-material/PriorityHigh'

import { useAppDispatch, useAppSelector } from '../../app/hooks'
import { closeModal, ModalType, openModal } from '../../features/modal/modalSlice'
import { BridgeDirectionName, setDualDirection } from '../../features/bridge/bridgeSlice'
import { getUnfinishedBridgeTx, selectCurrent } from '../../features/wallet/transactionSlice'
import useFetchBridgeCurrencies from '../../hooks/useFetchBridgeCurrencies'

interface UnfinishedBridgeTxButtonProps {
  id: string
  onlyIcon?: boolean
  sx?: SxProps<Theme>
}

const UnfinishedBridgeTxButton = memo((
  { id, onlyIcon = true, sx }: UnfinishedBridgeTxButtonProps,
): JSX.Element | null => {
  const theme = useTheme()
  const dispatch = useAppDispatch()
  const notFinishedTx = useAppSelector(getUnfinishedBridgeTx(id))
  const { bridgeCurrencies } = useFetchBridgeCurrencies()
  if (!notFinishedTx || !bridgeCurrencies) {
    return null
  }

  const depositCurrency = bridgeCurrencies.find((currency) => currency.equals(notFinishedTx.deposit.currency))
  const withdrawCurrency = bridgeCurrencies.find((currency) => currency.equals(notFinishedTx.withdraw.currency))

  if (!depositCurrency || !withdrawCurrency) {
    Sentry.captureMessage('Deposit/withdraw currency not found')
    return null
  }

  const resumeTx = () => {
    dispatch(closeModal())
    dispatch(setDualDirection({
      [BridgeDirectionName.SOURCE]: {
        chainId: notFinishedTx.deposit.currency.chainId,
        currency: depositCurrency,
      },
      [BridgeDirectionName.TARGET]: {
        chainId: notFinishedTx.withdraw.currency.chainId,
        currency: withdrawCurrency,
      },
    }))
    dispatch(selectCurrent(notFinishedTx.id))
    dispatch(openModal(ModalType.BRIDGE_TRANSFER))
  }

  if (onlyIcon) {
    return (
      <Button onClick={resumeTx}>
        <PriorityHighIcon
          sx={{
            ...sx,
            color: (theme.palette.mode === 'light') ? 'red' : 'red',
          }}
        />
      </Button>
    )
  }

  return (
    <Button
      color="warning"
      size="small"
      variant="outlined"
      onClick={resumeTx}
      sx={sx}
    >
      Resume
    </Button>
  )
})

UnfinishedBridgeTxButton.displayName = 'UnfinishedBridgeTxButton'

export default UnfinishedBridgeTxButton
