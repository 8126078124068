import { useMemo } from 'react'

import { Web3Provider, TransactionReceipt } from '@ethersproject/providers'

import { useWeb3RequestQuery } from '../features/api/web3ApiSlice'

interface FetchTransactionsProps {
  provider?: Web3Provider
  txHashes: Array<string>
  pollingInterval?: number
}

interface FetchTransactionsAction {
  data?: Array<null | TransactionReceipt>
  isSuccess: boolean
  isError: boolean
  isFetching: boolean
  isLoading: boolean
}

const DefaultPollingInterval = 15_000

const prepareRequest = (txHash: string) => ({
  method: 'getTransactionReceipt',
  params: [txHash],
})

const useFetchTransactions = ({
  provider,
  txHashes,
  pollingInterval = DefaultPollingInterval,
}: FetchTransactionsProps): FetchTransactionsAction => {
  const request = useMemo(() => (txHashes.map((txHash) => prepareRequest(txHash))), [txHashes])

  const { data, ...queryData } = useWeb3RequestQuery({
    provider,
    request,
  }, {
    pollingInterval,
    skip: !request.length,
  })

  const response = useMemo(() => (!data ? [] : data as Array<null | TransactionReceipt>), [data])

  return {
    data: response,
    ...queryData,
  }
}

export default useFetchTransactions
