import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ethers } from 'ethers'

interface WalletState {
  chainId: number
  account: string
  loaded: boolean
  isAuthorized: boolean
  // when user manually deauthorize, metamask does not now anything
  // so some simulation should be added
  forcedExit: boolean
}

interface AuthorizeData {
  chainId: number
  account: string
}

const initialState: WalletState = {
  chainId: 1,
  account: ethers.constants.AddressZero,
  loaded: false,
  isAuthorized: false,
  forcedExit: false,
}

export const walletSlice = createSlice({
  name: 'wallet',
  initialState,
  reducers: {
    setLoaded: (state, action: PayloadAction<boolean>) => {
      state.loaded = action.payload
    },
    authorize: (state, action: PayloadAction<AuthorizeData>) => {
      state.loaded = true
      state.isAuthorized = true
      state.chainId = action.payload.chainId
      state.account = action.payload.account
    },
    deauthorize: (state, action: PayloadAction<boolean | undefined>) => {
      state.loaded = true
      state.isAuthorized = false
      if (typeof action.payload !== 'undefined') {
        state.forcedExit = action.payload
      }
    },
    deforceWallet: (state) => {
      state.forcedExit = false
    },
  },
})

export const {
  setLoaded, authorize, deauthorize, deforceWallet,
} = walletSlice.actions

export default walletSlice.reducer
