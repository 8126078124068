import { createWeb3ReactRoot } from '@web3-react/core'
import {
  ExternalProvider,
  JsonRpcFetchFunc,
  Web3Provider,
} from '@ethersproject/providers'
import { ConnectorNames } from '../helpers/connectors'

// monkey patch during this issue
// comments from dev: no comments...
// https://github.com/NoahZinsmeister/web3-react/issues/173
const originalFetch = window.fetch
window.fetch = (url, opts): Promise<Response> => {
  if (opts?.body && opts.body.toString().includes('jsonrpc')) {
    opts.headers = opts.headers || {
      'Content-Type': 'application/json',
    }
  }
  return originalFetch(url, opts)
}

export interface CustomExternalProvider extends ExternalProvider {
  // extend request method to accept also array
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  request?: (request: { method: string, params?: Array<any> | any }) => Promise<any>
}

declare global {
  interface Window {
    ethereum: CustomExternalProvider;
  }
}

export const getLibrary = (
  provider: CustomExternalProvider | JsonRpcFetchFunc,
): Web3Provider => new Web3Provider(provider)

export const Web3SourceProvider = createWeb3ReactRoot(ConnectorNames.SourceNetwork)
export const Web3TargetProvider = createWeb3ReactRoot(ConnectorNames.TargetNetwork)
export const Web3RefetcherProvider = createWeb3ReactRoot(ConnectorNames.Refetcher)
