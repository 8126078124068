import { Navigate } from 'react-router-dom'
import Bridge from './components/pages/Bridge'

const Home = (): JSX.Element => <Navigate to="/bridge" />

const baseRoutes = [
  {
    name: 'Home',
    disabled: false,
    path: '/',
    component: Home,
  },
  {
    name: 'Swap',
    disabled: true,
    path: '/swap',
    component: null,
  },
  {
    name: 'Pool',
    disabled: true,
    path: '/pool',
    component: null,
  },
  {
    name: 'Farm',
    disabled: true,
    path: '/farm',
    component: null,
  },
  {
    name: 'Bridge',
    disabled: false,
    path: '/bridge',
    component: Bridge,
  },
  {
    name: 'Stake',
    disabled: true,
    path: '/stake',
    component: null,
  },
  {
    name: 'Docs',
    disabled: false,
    path: 'https://docs.oneledger.io',
    component: null,
  },
]

export default baseRoutes
