import axios, { AxiosError } from 'axios'
import { createApi, fakeBaseQuery } from '@reduxjs/toolkit/query/react'
import { TokenInfo, TokenList } from '@uniswap/token-lists'

const fetchTokens = async (url: string) => {
  try {
    console.log('Fetch tokens list on:', url)
    const result = await axios.get<TokenList>(url)
    return { data: result.data.tokens }
  } catch (axiosError) {
    const err = axiosError as AxiosError
    return {
      error: { status: err.response?.status, data: err.response?.data },
    }
  }
}

const tokenListApiSlice = createApi({
  reducerPath: 'tokenListApi',
  baseQuery: fakeBaseQuery(),
  endpoints: (builder) => ({
    fetchTokens: builder.query<TokenInfo[], string | string[]>({
      queryFn: async (urls) => {
        if (!Array.isArray(urls)) {
          return fetchTokens(urls)
        }

        const results = await Promise.all(
          urls.map((url) => fetchTokens(url)),
        )
        return results.reduce((store, result) => {
          if (!result.error) {
            result.data.forEach((responseToken) => {
              const index = store.data.findIndex((storedToken) => (
                storedToken.address.toLowerCase() === responseToken.address.toLowerCase()
                && storedToken.chainId === responseToken.chainId
              ))
              if (index === -1) {
                store.data.push(responseToken)
              } else {
                const bridgeInfo = responseToken.extensions?.bridgeInfo as unknown as {
                  [chainId: string]: {
                    tokenAddress: string,
                    destBridgeAddress: string,
                    originBridgeAddress: string
                  }
                } | undefined
                if (bridgeInfo) {
                  store.data[index] = responseToken
                }
              }
            })
          }
          return store
        }, { data: [] } as { data: TokenInfo[] })
      },
    }),
  }),
})

export const {
  useFetchTokensQuery,
} = tokenListApiSlice

export default tokenListApiSlice
